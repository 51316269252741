import { FullWidthBannerProps } from "@slices/FullWidthBanner";
import { FullWidthBannerSliceTextOverlayPrimary } from "prismicio-types";
import React from "react";

import { ScrollElement } from "@components/ScrollElement";

import { ImageBackground, TextOverlay } from "../components";

import * as S from "../styles";

const FullwidthBanner = ({ slice, index }: FullWidthBannerProps) => {
  const { image, slice_id, ...other } =
    slice.primary as unknown as FullWidthBannerSliceTextOverlayPrimary;

  if (!image?.url) {
    return null;
  }

  return (
    <S.FullWidthBanner>
      <ScrollElement
        id={(slice_id as string) || `full-Width-banner-${index}`}
      />
      <ImageBackground image={image} index={index} />
      <TextOverlay buttonType="light" overlayVariant="default" {...other} />
    </S.FullWidthBanner>
  );
};

export default FullwidthBanner;
