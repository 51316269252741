import Image from "next/image";
import { FullWidthBannerSliceDefaultPrimary } from "prismicio-types";
import React from "react";

import { useIsMdUp } from "@hooks/responsive";

import * as S from "../../styles";

export type ImageBackgroundProps = Pick<
  FullWidthBannerSliceDefaultPrimary,
  "image"
> & {
  index: number;
};

export const ImageBackground = ({ image, index }: ImageBackgroundProps) => {
  const isMdUp = useIsMdUp();
  const url = isMdUp ? image?.url : image?.mobile?.url ?? image?.url;

  if (!image?.url) {
    return null;
  }

  return (
    <S.ImageWrapper>
      {url && (
        <Image
          alt={image?.alt ?? ""}
          layout="fill"
          loading={index === 0 ? "eager" : "lazy"}
          objectFit="cover"
          priority={index === 0}
          src={url}
        />
      )}
    </S.ImageWrapper>
  );
};
