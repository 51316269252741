import { paths } from "@paths";
import { asLink } from "@prismicio/client";
import { PrismicRichText } from "@prismicio/react";
import Link from "next/link";
import {
  FullWidthBannerSliceDefaultPrimary,
  FullWidthBannerSliceLightPrimary,
} from "prismicio-types";
import React from "react";

import { Button } from "@components/Button";
import { ButtonVariant } from "@components/Button/styles";
import { Container } from "@components/Container";

import * as S from "../../styles";

type FullWidthBannerSlicesssVariation =
  | FullWidthBannerSliceDefaultPrimary
  | FullWidthBannerSliceLightPrimary;

export type ImageOverlayVariant = "default" | "light";

type ImageOverlayProps = {
  buttonType: ButtonVariant;
  overlayVariant: ImageOverlayVariant;
} & Omit<FullWidthBannerSlicesssVariation, "image" | "slice_id">;

export const ImageOverlay = ({
  overlayVariant,
  title,
  description,
  buttonType,
  button_active,
  button_link,
  button_text,
}: ImageOverlayProps) => (
  <S.FullWidthBannerOverlay variant={overlayVariant}>
    <Container disableGutters>
      <S.InnerWrapper variant={overlayVariant}>
        <div className="textContainer">
          {title && <PrismicRichText field={title} />}
          {description && <PrismicRichText field={description} />}
        </div>
        {button_active && asLink(button_link) && (
          <Link passHref href={asLink(button_link) || paths.home}>
            <Button variant={buttonType}>{button_text}</Button>
          </Link>
        )}
      </S.InnerWrapper>
    </Container>
  </S.FullWidthBannerOverlay>
);
