import { media, px2rem, styled } from "@styles";

import { ImageOverlayVariant } from "./components/ImageOverlay/ImageOverlay";
import { TextOverlayVariant } from "./components/TextOverlay/TextOverImage";

export type TextOverlayVariantProps = {
  opacity?: string;
  variant?: TextOverlayVariant;
};

export const FullWidthBannerTextOverlay = styled.div<TextOverlayVariantProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;

  color: ${({ variant, theme }) =>
    variant === "default"
      ? theme.colors.white
      : variant === "light"
      ? theme.colors.black
      : "none"};

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    opacity: ${({ opacity }) =>
      opacity === "Light" ? 0.5 : opacity === "Dark" ? 0.8 : 0};

    background-color: ${({ variant, theme }) =>
      variant === "default"
        ? theme.colors.black
        : variant === "light"
        ? theme.colors.white
        : "none"};
  }

  ${media.md.up} {
    flex-direction: row;
  }
`;

export const FullWidthBanner = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 auto;
  z-index: 0;
  box-sizing: border-box;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  aspect-ratio: 2/3;

  ${media.md.up} {
    height: 100%;
    width: 100%;
    aspect-ratio: 5/2;
  }

  ${media.lg.up} {
    aspect-ratio: 3/1;
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  aspect-ratio: 16/9;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const FullWidthBannerOverlay = styled.div<{
  variant: "default" | "light";
}>`
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 100%;
  padding: 20px;
  gap: 20px;
  bottom: 0;
  left: 0;

  background-color: ${({ variant, theme }) =>
    variant === "default"
      ? theme.colors.black
      : variant === "light"
      ? theme.colors.white
      : "none"};

  ${media.md.up} {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 26px 48px;
    gap: 0;
  }
`;

export const InnerWrapper = styled.div<{
  variant: ImageOverlayVariant;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  .textContainer {
    display: flex;
    gap: inherit;
    flex-direction: column;
    justify-content: center;
    max-width: 80vw;
    max-height: 30vh !important;

    color: ${({ variant, theme }) =>
      variant === "default"
        ? theme.colors.white
        : variant === "light"
        ? theme.colors.black
        : "none"};

    p {
      font-weight: 600;
    }

    ${media.md.up} {
      max-width: 40vw;
    }
  }
`;

export const TextOverImageInnerWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1;

  gap: 1rem;

  a:not(.button-link) {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.black};
  }

  a:not(.button-link):visited {
    color: ${({ theme }) => theme.colors.dark};
  }

  a.button-link {
    margin-top: 1rem;
  }

  p {
    font-weight: 400;
  }

  ${media.md.up} {
    gap: 1.8rem;
    padding: 0 2rem;
    max-width: 70%;

    p {
      font-size: ${px2rem(17)};
      max-width: 90%;
    }
  }
`;
