import { FullWidthBannerSliceLightPrimary } from "prismicio-types";
import React from "react";

import { ScrollElement } from "@components/ScrollElement";

import { FullWidthBannerProps } from "../..";
import { ImageBackground, ImageOverlay } from "../components";

import * as S from "../styles";

const FullWidthBanner = ({
  slice,
  index,
}: FullWidthBannerProps): JSX.Element | null => {
  const { image, slice_id, ...other } =
    slice.primary as unknown as FullWidthBannerSliceLightPrimary;

  if (!image?.url) {
    return null;
  }

  return (
    <>
      <S.FullWidthBanner>
        <ScrollElement
          id={(slice_id as string) || `full-Width-banner-${index}`}
        />
        <ImageBackground image={image} index={index} />
      </S.FullWidthBanner>
      <ImageOverlay buttonType="secondary" overlayVariant="light" {...other} />
    </>
  );
};

export default FullWidthBanner;
