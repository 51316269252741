import { FullWidthBannerSliceVideoBannerPrimary as OriginalFullWidthBannerSliceVideoBannerPrimary } from "prismicio-types";
import React from "react";

import { ScrollElement } from "@components/ScrollElement";

import * as S from "../styles";

interface VideoLink {
  id: string;
  kind: "video";
  link_type: "Media";
  name: string;
  size: string;
  url: string;
}

interface CustomFullWidthBannerSliceVideoBannerPrimary
  extends OriginalFullWidthBannerSliceVideoBannerPrimary {
  video: VideoLink;
}

interface FullWidthBannerPropsExtended {
  index: number;
  slice: {
    primary: CustomFullWidthBannerSliceVideoBannerPrimary;
  };
}

const FullwidthBanner = ({ slice, index }: FullWidthBannerPropsExtended) => {
  const { video, slice_id } = slice.primary;

  return (
    <S.FullWidthBanner>
      <ScrollElement
        id={(slice_id as string) || `full-Width-banner-${index}`}
      />
      {video && (
        <S.VideoWrapper>
          <video autoPlay loop muted>
            <source src={video.url} type="video/mp4" />
          </video>
        </S.VideoWrapper>
      )}
    </S.FullWidthBanner>
  );
};

export default FullwidthBanner;
